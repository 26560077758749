import React from "react";
import { Table } from "antd";

export default function TableInvoiceBook(props) {

  const dataTable = [
    {
      "invoice_id": "FACTGR12",
      "invoice_date": "12/12/2012",
      "social_reason_fact": "Clientes varios",
      "client_info": "clientes varios",
      "sales_channel": "atrapalo",
      "delivery_list": "ALBGR12",
      "base_import": "1000,150",
      "vat_type": "21,10",
      "vat_import": "210,15",
      "total_import": "1375"
    }
  ]

  const columns = [
    {
      title: 'Fecha',
      dataIndex: 'invoice_date',
      key: 'invoice_date',
    },
    {
      title: 'Num. Factura',
      dataIndex: 'invoice_id',
      key: 'invoice_id',
    },
    {
      title: 'Cliente - Razón social',
      dataIndex: 'social_reason_fact',
      key: 'social_reason_fact',
    },
    {
      title: 'CIF',
      dataIndex: 'client_info',
      key: 'client_info',
    },
    {
      title: 'Dirección completa',
      dataIndex: 'client',
      key: 'client',
    },
    {
      title: 'Base Imponible',
      dataIndex: 'base_import',
      key: 'base_import',
    },
    {
      title: 'Tipo VAT',
      dataIndex: 'vat_type',
      key: 'vat_type',
    },
    {
      title: 'Importe VAT',
      dataIndex: 'vat_import',
      key: 'vat_import',
    },
    {
      title: 'TOTAL',
      dataIndex: 'total_import',
      key: 'total_import',
    },
  ]

  return (
    <>
      <Table
        columns={columns}
        dataSource={dataTable} 
        bordered
        className="mainTable"
      />
    </>
  )
}