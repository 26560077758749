import React, {useEffect, useState} from "react";
import apiManager from "../../api/apiManager";
import { Form, Row, Col, Select, Input, Button, Tooltip, InputNumber } from "antd";
import { SwapRightOutlined, PlusCircleFilled, MinusCircleFilled, UserOutlined } from "@ant-design/icons";
import { Cancel } from "@mui/icons-material";

import dayjs from "dayjs";
import "./reasignresources.css";

export default function ReasignResourcesPax (props) {

  //console.log(props)
  const [resourcesList, setResourcesList] = useState([])
  const [formReasign] = Form.useForm()

  const [selectResource, setSelectResource] = useState(0)
  const [selectedResources, setSelectedResources] = useState([{
    value: props.data.resource_id,
    label: props.data.resource_title,
    num_available: props.data.num_pax_total,
    disabled: true,
    inputValue: props.data.num_pax
  }])
  const [avPersons, setAvPersons] = useState(0)
  const [listResourcesKey, setListResourcesKey] = useState(0)

  useEffect(() => {
    formReasign.setFieldValue("newResources", [{
      disabled: true,
      label: `${props.data.resource_title} (${props.data.num_pax_total} disponibles)`,
      num_available: props.data.num_pax_total,
      value: props.data.resource_id,
    }])
    apiManager.getResourcesBooking(
      props.data.turn_id,
      props.data.cat_act_id,
      JSON.parse(localStorage.getItem("activeCenter")).id,
      dayjs(props.data.start).format("DD-MM-YYYY"),
      props.data.num_pax,
      1
    )
    .then((res) => {
      //console.log(res.data.data)
      let result = res.data.data
      let orderResources = []
      setResourcesList([])
      result.map((resource) => {
        //console.log(selectedResources)
        let dataValue = {
          'value': resource.id,
          'label': resource.title,
          'num_available': selectedResources.some(e => e.value === resource.id) ? resource.num_available + selectedResources.find(e => e.value === resource.id).inputValue : resource.num_available,
          'by_unit': resource.by_unit,
          'text': resource.text,
          'disabled': selectedResources.some(e => e.value === resource.id)
        }
        //setResourcesList(current => [...current, dataValue])
        orderResources.push(dataValue)
      })
      selectedResources.map((res) => {
        if (!result.some(e => e.id === res.value)) {
          let dataValue = {
            'value': res.value,
            'label': res.label,
            'num_available': res.inputValue,
            'by_unit': false,
            'text': res.label,
            'disabled': true
          }
          //setResourcesList(current => [...current, dataValue])
          orderResources.push(dataValue)
        }
      })
      setResourcesList(orderResources.sort((a,b) => a.value - b.value))
    })
  }, [])

  const reasignResource = (values) => {
    let data = {
      day: dayjs(props.data.start).format("DD-MM-YYYY"),
      turn: props.data.turn_id,
      activity: props.data.cat_act_id,
      center: JSON.parse(localStorage.getItem("activeCenter")).id,
      resource_occupation_id: props.data.event_id,
      occupations: selectedResources.map(resource => {
        return {
          resource_id: resource.value,
          resource_occupation: resource.inputValue
        }
      })
    }
    console.log(data)
    apiManager.reasignResourcePax(data)
    .then((res) => {
      console.log(res.data.data)
      props.closeReasign()
    })
  }

  const filteredOptions = resourcesList

  const changeSelectedResources = (value) => {
    //console.log(value)
    let selectedValue = value.at(-1).value
    //setSelectedResources(current => [...current, selectedValue])
    formReasign.setFieldValue('newResources', value)
    var foundIndex = filteredOptions.findIndex(x => x.value === selectedValue)
    filteredOptions[foundIndex].disabled = true
    setSelectResource(selectResource + 1)
    value.map((item) => {
      let valueToInput = avPersons <= item.num_available ? avPersons : item.num_available
      if (selectedResources.length > 0) {
        if (!selectedResources.some(e => e.value === item.value)) {
          //console.log(item)
          setSelectedResources(current => [...current, {
            value: item.value,
            label: item.label,
            num_available: item.num_available,
            disabled: item.disabled,
            inputValue: valueToInput
          }])
          setAvPersons(current => current - valueToInput)
        }
      } else {
        setSelectedResources(current => [...current, {
          value: item.value,
          label: item.label,
          num_available: item.num_available,
          disabled: item.disabled,
          inputValue: valueToInput
        }])
        setAvPersons(current => current - valueToInput)
      }
    })
  }

  const removeSelectedResourceNoUnit = (value) => {
    let selectedValue = value
    let foundIndex = filteredOptions.findIndex(x => x.value === selectedValue)
    filteredOptions[foundIndex].disabled = false
    let newListado2 = [...selectedResources]
    let selectedIndex2 = newListado2.findIndex(x => x.value === selectedValue)
    let selectedInputValue = newListado2[selectedIndex2].inputValue
    newListado2.splice(selectedIndex2, 1)
    formReasign.setFieldValue('newResources', newListado2)
    setSelectedResources(newListado2)
    setSelectResource(selectResource + 1)
    setListResourcesKey(listResourcesKey + 1)
    setAvPersons(current => current + selectedInputValue)
  }

  const incressValue = (id) => {
    let alterArray = [...selectedResources]
    let foundIndex = alterArray.findIndex(x => x.value === id)
    if (alterArray[foundIndex].inputValue !== undefined) {
      alterArray[foundIndex].inputValue = alterArray[foundIndex].inputValue + 1
    } else {
      alterArray[foundIndex].inputValue = 2
    }
    setAvPersons(current => current - 1)
    setListResourcesKey(listResourcesKey + 1)
  }

  const decressValue = (id) => {
    let alterArray = [...selectedResources]
    let foundIndex = alterArray.findIndex(x => x.value === id)
    if (alterArray[foundIndex].inputValue !== undefined) {
      alterArray[foundIndex].inputValue = alterArray[foundIndex].inputValue - 1
    }
    setAvPersons(current => current + 1)
    setListResourcesKey(listResourcesKey + 1)
  }

  return (
    <>
      <Form
        form={formReasign}
        layout="vertical"
        className="reasignResourceForm"
        onFinish={() => reasignResource(formReasign.getFieldsValue())}
      >
        <Row justify="space-between" style={{alignItems: "flex-end", marginTop: 20}}>
          <Col span={24}>
            <h6
              style={{fontSize: 16, fontWeight: 600, marginBottom: 20}}
            >Reasignar recursos de la actividad <span className="primaryColor">{ props.data.title }</span> de las <span className="primaryColor">{ dayjs(props.data.start).format("HH:mm") }</span>h</h6>
          </Col>
          <Col span={24}>
            {/* <Form.Item
              label="Nuevo recurso"
              name="newResource"
              rules={[
                {
                  required: true,
                  message: "Este campo es obligatorio"
                },
              ]}
            >
              <Select
                placeholder="Selecciona un nuevo recurso"
                options={resourcesList}
              />
            </Form.Item> */}
            <p style={{fontSize: 14, position: "absolute", top: -3, right: 0}}>
              Personas sin asignar: <b style={{fontSize: 16}}>{ avPersons }</b>
            </p>
            <Form.Item
              //label={`Recurso:`}
              label="Recursos:"
              name="newResources"
            >
              <Select
                mode="tags"
                key={selectResource}
                placeholder="Selecciona un recurso"
                value={resourcesList}
                maxTagCount={0}
                onChange={(e, a) => changeSelectedResources(a)}
                disabled={avPersons === 0}
                className="resources-select-nounit"
                style={{
                  width: '100%',
                }}
                options={filteredOptions.map((item) => ({
                  value: item.value,
                  label: `${item.text} (${item.num_available} disponibles)`,
                  disabled: item.disabled,
                  num_available: item.num_available
                }))}
              />
            </Form.Item>
            <Row justify="space-around" key={listResourcesKey}>
              {
                selectedResources.map((resource) => (
                  <Col span={6}>
                    <div style={{backgroundColor: "#ececec", padding: "6px 10px", textAlign: "center", borderRadius: 5, marginBottom: 15, position: "relative"}}>
                      <Tooltip title="Quitar recurso">
                        <Cancel style={{position: "absolute", top: -8, right: -8}} onClick={() => {removeSelectedResourceNoUnit(resource.value)}} />
                      </Tooltip>
                      <p>{ filteredOptions.some(x => x.value === resource.value) ? filteredOptions.find(x => x.value === resource.value).text : "" }</p>
                      <InputNumber
                        addonBefore={<UserOutlined />}
                        value={resource.inputValue !== undefined ? resource.inputValue : 1}
                        min={1}
                        max={filteredOptions.some(e => e.value === resource.value) ? filteredOptions.find(e => e.value === resource.value).num_available : ""}
                        disabled={true}
                        className="resource-input-value"
                        style={{width: '70px', margin: '5px 0 2px'}}
                      />
                      <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Button
                          className="iconCircle icon-24"
                          disabled={resource.inputValue === undefined || resource.inputValue === 1 ? true : false}
                          style={{backgroundColor: "transparent"}}
                          onClick={() => decressValue(resource.value)}
                        >
                          <MinusCircleFilled className="greyColor" />
                        </Button>
                        <Button
                          className="iconCircle icon-24"
                          style={{marginInline: '5px', backgroundColor: "transparent"}}
                          disabled={resource.inputValue >= (filteredOptions.some(e => e.value === resource.value) ? filteredOptions.find(e => e.value === resource.value).num_available : 0) || avPersons === 0 ? true : false}
                          onClick={() => incressValue(resource.value)}
                        >
                          <PlusCircleFilled className="greyColor" />
                        </Button>
                      </div>
                    </div>
                  </Col>
                ))
              }
            </Row>
          </Col>
          <Col span={24} style={{marginTop: 10}}>
            <Form.Item
              style={{marginBottom: 0, display: "flex", justifyContent: "center"}}
            >
              <Button
                className="basic-btn"
                onClick={() => props.closeReasign()}
              >Cancelar</Button>
              <Button
                className="main-btn"
                style={{marginLeft: 20}}
                disabled={avPersons !== 0}
                htmlType="submit"
              >Reasignar</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}