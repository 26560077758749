import React from "react";
import { DatePicker, Button, Row, Col, Select } from "antd";
import { Refresh } from "@mui/icons-material";
import TableInvoiceBook from "../../componentes/Tabla/TableInvoiceBook";

export default function InvoiceBook() {

  const dateFormat = "DD-MM-YYYY"
  const { RangePicker } = DatePicker

  const findForDate = (values) => {
    console.log(values)
  }

  return (
    <>
      <Row
        justify={'space-between'}
        style={{marginBottom: 20}}
        //className={styles.header}
      >
        <Col span={8} style={{display: "flex", justifyContent: "left", alignItems: "center", gap: 8}}>
          <Select
            style={{ width: 200 }}
            placeholder="Selecciona una razón social"
            //onChange={(e) => changeFamily(e)}
            //value={familySelected}
            options={[]}
          />
          <RangePicker
            format={dateFormat}
            onChange={(e, a) => findForDate(a) }
            placeholder={["Desde", "Hasta"]}
          />
          <Button
            disabled={true}
            //onClick={() => refreshFamilySelected()}
          ><Refresh /></Button>
        </Col>
        <Col span={8}></Col>
        <Col span={8}></Col>
      </Row>
      <TableInvoiceBook />
    </>
  )
}