import React, { useState } from "react";
import { Routes, Route, useNavigate, useLocation, Router } from "react-router-dom";
import Auth from "../src/componentes/Auth/auth.js";
import PrivateRoute from "./componentes/PrivateRoute/PrivateRoute.js";
import NewAuth from "./componentes/Auth/NewAuth.js";
import Reservas from "../src/pages/Reservas/reservas.js";
import Actividades from "../src/pages/Actividades/actividades.js";
import BuscadorReservas from "./pages/BuscadorReservas/BuscadorReservas.js";
import MensualProduction from "./pages/Informes/MensualProduction.js";
import ChannelProduction from "./pages/Informes/ChannelProduction.js";
import ClosedCash from "./pages/Informes/ClosedCash.js";
import ActivitiesReports from "./pages/Informes/ActivitiesReports.js";
import ValidateBookings from "./pages/ValidateBookings/ValidateBookings.js";
import InvoiceIncomplete from "./pages/InvoiceIncomplete/InvoiceIncomplete.js";
import DeliveryList from "./pages/Facturas/DeliveryList.js";
import InvoiceList from "./pages/Facturas/InvoiceList.js";
import InvoiceBook from "./pages/Facturas/InvoiceBook.js";
import TreasuryBook from "./pages/Facturas/TreasuryBook.js";
import Reportings from "./pages/Facturas/Reportings.js";
import RectInvoice from "./pages/Facturas/RectInvoice.js";
import MainB2B from "./pages/B2B/Main.js";
import ReservasB2B from "./pages/B2B/ReservasB2B.js";
import BuscadorB2B from "./pages/B2B/BuscadorB2B.js";
import MainLayout from "./layouts/MainLayout.js";
import B2BMainLayout from "./layouts/B2BMainLayout.js";
import Page404 from "./componentes/Error/Page404.js";
import apiManager from "./api/apiManager.js";
import WebRoutes from "./pages/WebRoutes/index.js";

import { UserProvider, useUser } from "./api/context/userContext.js";

import "./App.css";
import { useEffect } from "react";

function App() {
  const [userLoggedIn, setUserLoggedIn] = useState(false)
  //const userToken = localStorage.getItem("token")
  const [isB2B, setIsB2B] = useState(false)

  const { getUserData, userData } = useUser()

  const navigate = useNavigate();

  let isBillingUser = false

  const permissonsRequired = [
    "/produc-mensual", "/produc-canal", "/informe-actividades"
  ]

  //today
  function convert(str) {
    let date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day, month, date.getFullYear()].join("-");
  }

  //today
  let today = new Date()
  let location = useLocation()
  const dd = String(today.getDate()).padStart(2, "0")
  const mm = String(today.getMonth() + 1).padStart(2, "0")
  const yyyy = today.getFullYear()
  today = dd + "-" + mm + "-" + yyyy

  convert(today)

  /* const refreshScreen = () => {
    setUserLoggedIn(true)
    if (localStorage.getItem("isb2b") === "false") {
      navigate("/reservas")
    } else {
      navigate("/b2b/reservas")
    }
  } */

  useEffect(() => {
    if (localStorage.getItem("token") === null) {
      setUserLoggedIn(false)
    } else {
      setUserLoggedIn(true)
      getUserData()
      redirectCheck()
    }
    localStorage.setItem("today", JSON.stringify(today))
    let darkMode = localStorage.getItem("viewMode") !== null ?
        localStorage.getItem("viewMode") === "true" ?
          true
        : false
      : true
    if (darkMode) {
      document.body.classList.remove('dark-mode')
    } else {
      document.body.classList.add('dark-mode')
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      apiManager.isBillingAdmin(localStorage.getItem("token"))
      .then((res) => {
        let result = res.data.data[0]
        isBillingUser = result.user_is_billing_admin
        checkPermissons(result.user_is_billing_admin)
      })
    }
  }, [location])

  /* useEffect(() => {
    console.log('navigate')
    if (localStorage.getItem("token") === null) {
      setUserLoggedIn(false)
    } else {
      setUserLoggedIn(true)
    }
  }, [navigate]) */

  /* const checkB2B = () => {
    console.log('check!')
    apiManager.isB2B()
    .then((res) => {
      let result = res.data.data[0]
      console.log(result.user_is_b2_b)
      if (result.user_is_b2_b) {
        navigate("/b2b/dashboard")
      } else {
        navigate("/reservas")
      }
    })
  } */

  const redirectCheck = () => {
    let urlArray = location.pathname.split("/").filter(n => n)
    if (location.pathname === "/" || (urlArray.length === 1 && urlArray[0] === "b2b")) {
      if (userData !== null) {
        if (!userData.is_b2b) {
          navigate("/reservas")
        } else {
          navigate("/b2b/reservas")
        }
      }
    } else if (location.pathname === "/informes") {
      navigate("/produc-mensual")
    }
  }

  const checkPermissons = (billingUser) => {
    if (!billingUser && permissonsRequired.includes(location.pathname)) {
      if (localStorage.getItem("isb2b") === "false") {
        navigate("/reservas")
      } else {
        navigate("/b2b/reservas")
      }
    } else {
      redirectCheck()
    }
  }

  return (
    <React.Fragment>
      <WebRoutes />
      {/* <Routes>
        <Route exact path="/" element={<NewAuth refreshScreen={refreshScreen} />} />
        <Route exact path="/reservas"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Reservas"
              >
                <Reservas />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/actividades"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Actividades"
              >
                <Actividades />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/reservas-buscador"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Buscador de reservas"
              >
                <BuscadorReservas />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/produc-mensual"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Producción mensual"
              >
                <MensualProduction />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/produc-canal"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Producción por canal de venta"
              >
                <ChannelProduction />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/cierres-caja"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Cierre de caja"
              >
                <ClosedCash />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/informe-actividades"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Informes de actividades"
              >
                <ActivitiesReports />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/validar-reservas"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Reservas a validar"
              >
                <ValidateBookings />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/facturacion-incompleta"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Facturación incompleta"
              >
                <InvoiceIncomplete />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/listado-albaranes"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Listado albaranes"
              >
                <DeliveryList />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/listado-facturas"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Listado facturas"
              >
                <InvoiceList />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/libro-facturas"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Libro de facturas"
              >
                <InvoiceBook />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/libro-tesoreria"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Libro de tesorería"
              >
                <TreasuryBook />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/reportings"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Reportings"
              >
                <Reportings />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/facturas-rect"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Facturas rectificativas"
              >
                <RectInvoice />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/b2b/reservas"
          element={
            <PrivateRoute b2b={true}>
              <B2BMainLayout
                title="Reservas"
              >
                <ReservasB2B />
              </B2BMainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/b2b/reservas-buscador"
          element={
            <PrivateRoute b2b={true}>
              <B2BMainLayout
                title="Buscador de reservas"
              >
                <BuscadorB2B />
              </B2BMainLayout>
            </PrivateRoute>
          }
        />
        <Route path='/informes' element={<></>}/>
        <Route path='/facturas' element={<></>}/>
        <Route path='/b2b' element={<></>}/>
        <Route path='*' element={<Page404 />}/>
      </Routes> */}
    </React.Fragment>
  )
}

export default () => <UserProvider><App /></UserProvider>;
