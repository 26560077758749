import React, { useEffect, useState, useRef } from "react"
import { Layout } from "antd"
import B2BMainMenu from "./B2BMainMenu";
import B2BMainHeader from "./B2BMainHeader";
import Loader from "../componentes/Loader/Loader";
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import apiManager from "../api/apiManager";

export default function B2BMainLayout ({children, ...pageProps}) {

  const { Header, Footer, Content } = Layout;
  const [collapsedMenu, setCollapsedMenu] = useState(localStorage.getItem('menuCollapsed') === 'false' ? false : true)
  const [isLoading, setIsLoading] = useState(false)
  const [userInfo, setUsetInfo] = useState({})
  const [view, setView] = useState(
    localStorage.getItem("viewMode") !== null ?
      localStorage.getItem("viewMode") === "true" ?
        true
      : false
    : true
  )
  let navigate = useNavigate()
  let location = useLocation()

  useEffect(() => {
    setIsLoading(true)
    if (localStorage.getItem("token") !== null) {
      setIsLoading(false)
    } else {
      setIsLoading(false)
      navigate("/")
    }
    apiManager.userInformation()
    .then((res) => {
      let response = res.data.data[0]
      setUsetInfo(response)
    })
  }, [])

  const changeCollapsed = (value) => {
    localStorage.setItem('menuCollapsed', value)
    setCollapsedMenu(value)
  }

  const refreshPage = () => {
    window.location.reload(false)
  }

  function importAll(r) {
    let images = {}
    r.keys().map((item, index) => { images[item.replace('./', '').split('.').shift()] = r(item); })
    return images
  }

  const images = importAll(require.context('../componentes/Images', false, /\.(png|jpe?g|svg)$/))

  const updateView = (value) => {
    localStorage.setItem("viewMode", value)
    if (value) {
      document.body.classList.remove('dark-mode')
    } else {
      document.body.classList.add('dark-mode')
    }
    setView(value)
  }

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
      className={view ? '' : 'dark-view'}
    >
      <Loader controller={isLoading} />
      <Helmet>
        <title>
          {
            pageProps.title !== undefined ?
              `${pageProps.title} - ${JSON.parse(localStorage.getItem("activeCenter")).identify}`
            : "RocRoi"
          }
        </title>
        <meta name="description" content="App Description" />
        <link rel="icon" type="image/png" href={images.favicon} sizes="32x32" />
        {/* <script defer src="https://app.embed.im/snow.js" /> */}
      </Helmet>
      <B2BMainMenu
        changeCollapsed={changeCollapsed}
      />
      <Layout>
        <Header
          className={collapsedMenu ? "collapsed" : "uncollapsed"}
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 12,
            width: '100%',
            boxShadow: "0px 1px 10px #0000003d"
          }}
        >
          <B2BMainHeader
            userData={userInfo}
            refreshPage={refreshPage}
            updateView={updateView}
            viewMode={view}
          />
        </Header>
        <Content
          style={collapsedMenu ? {marginLeft: 80, padding: "20px 30px"} : {marginLeft: 200, padding: "20px 30px"}}
        >{ children }</Content>
        {/* <Footer>Footer</Footer> */}
      </Layout>
    </Layout>
  )
}