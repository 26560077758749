import React, { useRef, useState, useEffect } from "react";
import { Table, Row, Col, Form, Button, DatePicker, Select, Tag, Empty, Tabs, Modal, Input, Divider, Tooltip } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import apiManager, { salesChannelByCenter } from "../../api/apiManager";
import BookingInfo from "../Reserva/BookingInfo";
import BookingCreate from "../Reserva/BookingCreate";
import SchedulerResources from "./SchedulerResources";
import ReasignResources from "./ReasignResources";
import ReasignResourcesPax from "./ReasignResourcesPax";
import OverbookingResources from "./OverbookingResources";
import SchedulerTable from "./SchedulerTable";
import Loader from "../Loader/Loader";

import { RightOutlined, LeftOutlined, CheckCircleTwoTone, CloseCircleTwoTone, SearchOutlined } from "@ant-design/icons";
import { Refresh, Lock } from "@mui/icons-material";
import "./tableactivities.css";

//confirm dialog
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

export default function TableActivities (props) {

  const today = moment(Date.now()).format('DD-MM-YYYY')
  const dateFormat = 'DD-MM-YYYY'
  const dateSelected = useRef()
  const formRef = useRef()

  const [dataTable, setDataTable] = useState([])
  const [dataTableBooking, setDataTableBooking] = useState([])
  const [isAdmin, setIsAdmin] = useState(false)
  const [isCenterAdmin, setIsCenterAdmin] = useState(false)
  const [paginationItems, setPaginationItems] = useState(50)
  const [dateValue, setDateValue] = useState(dayjs(Date.now()).format('DD-MM-YYYY'))
  const [dataTableBase, setDataTableBase] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [familiesList, setFamiliesList] = useState([])
  const [familiesData, setFamiliesData] = useState([])
  const [filterFamilies, setFilterFamilies] = useState([])
  const [familySelected, setFamilySelected] = useState(null)
  const [familiesChildData, setFamiliesChildData] = useState([])
  const [channelList, setChannelList] = useState([])
  const [bookingIdSelected, setBookingIdSelected] = useState({})
  const [isModalBookingOpen, setIsModalBookingOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalReasignOpen, setIsModalReasignOpen] = useState(false)
  const [isModalOverbookingOpen, setIsModalOverbookingOpen] = useState(false)
  const [schedulerData, setSchedulerData] = useState({resources: [], events: [], date: new Date()})
  const [counter, setCounter] = useState(0)
  const [resorcesInfo, setResorcesInfo] = useState(null)
  const [overbookingTurns, setOverbookingTurns] = useState([])
  const [planningData, setPlanningData] = useState([])
  const [planningColumns, setPlanningColumns] = useState([])
  const [tableUpdate, setTableUpdate] = useState(0)
  const [fullTurns, setFullTurns] = useState([])
  const [schedulerFamily, setSchedulerFamily] = useState(null)
  const [schedulerText, setSchedulerText] = useState(null)

  const [form] = Form.useForm()
  const [formSearch] = Form.useForm()

  useEffect(() => {
    setIsLoading(true)
    apiManager.getTurnsByCenter(JSON.parse(localStorage.getItem("activeCenter")).id, today)
    .then((res) => {
      setFullTurns([])
      let turnsList = []
      res.data.data.map((turn) => {
        turnsList.push({
          'text': turn.title,
          'value': turn.title
        })
      })
      apiManager.planningFamilyTable(JSON.parse(localStorage.getItem("activeCenter")).id, today)
      .then((res) => {
        let result = res.data.data
        setPlanningData(result.turnsInfo)
        columnsPlanningSet(result.activities, turnsList)
        setIsLoading(false)
      })
    })
    apiManager.activitiesFilter(JSON.parse(localStorage.getItem("activeCenter")).id)
    .then((res) => {
      let result = res.data.data
      //console.log(res.data.data)
      setFamiliesData(result)
      setFamiliesList([])
      result.map((family) => {
        setFamiliesList(current => [...current, {
          'label': family.name,
          'value': family.id
        }])
      })
    })
    apiManager.isAdmin(localStorage.getItem("token"))
    .then((res) => {
      setIsAdmin(res.data.data[0].user_is_admin)
    })
    apiManager.isCenterAdmin(localStorage.getItem("token"))
    .then((res) => {
      setIsCenterAdmin(res.data.data[0].user_is_center_admin)
      setCounter(counter + 1)
    })
    //getActivitiesTable(JSON.parse(localStorage.getItem("activeCenter")).id, today, familySelected)
  }, [])

  /* useEffect(() => {
    console.log(planningColumns)
  }, [planningColumns]) */

  const columns = [
    {
      title: 'Hora',
      dataIndex: 'turn_info',
      sorter: (a, b) => a.turn_info.localeCompare(b.turn_info),
      filters: overbookingTurns,
      onFilter: (value, record) => record.turn_info.indexOf(value) === 0,
    },
    {
      title: 'Actividad',
      dataIndex: 'activity',
      filters: filterFamilies,
      onFilter: (value, record) => record.activity.indexOf(value) === 0,
    },
    /* {
      title: 'Plazas ocupadas',
      dataIndex: 'bussy_places'
    },
    {
      title: 'Plazas libres',
      dataIndex: 'av_places',
      filters: [
        {
          text: 'Con plazas libres',
          value: 0
        }
      ],
      onFilter: (value, record) => record.av_places > value,
    }, */
    {
      title: 'Recursos ocupadas',
      dataIndex: 'bussy_resources'
    },
    {
      title: 'Recursos libres',
      dataIndex: 'av_resources',
      filters: [
        {
          text: 'Con recursos libres',
          value: 0
        }
      ],
      onFilter: (value, record) => record.av_resources > value,
    },
    {
      title: 'Total reservas',
      dataIndex: 'num_booking'
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      filters: [
        {
          text: 'Pagado',
          value: 'Pagado',
        },
        {
          text: 'Parcial',
          value: 'Parcial',
        },
        {
          text: 'No pagado',
          value: 'No pagado',
        },
        {
          text: 'A devolver',
          value: 'A devolver',
        }
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (value) => {
        let color = '';
        if (value === 'Pagado') {
          color = 'green';
        } else if (value === 'Parcial') {
          color = 'orange';
        } else if (value === 'No pagado') {
          color = 'red';
        } else if (value === 'A devolver') {
          color = 'blue';
        }
        return (
          <Tag color={color}>
            {value}
          </Tag>
        );
      }
    }
  ]

  const columnsBooking = [
    {
      title: 'Hora',
      dataIndex: 'created_hour',
      key: 'created_hour',
      sorter: (a, b) => a.created_hour.localeCompare(b.created_hour),
    },
    {
      title: 'Actividad',
      dataIndex: 'activity'
    },
    {
      title: 'Datos reserva',
      dataIndex: 'client_info',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      filters: [
        {
          text: 'Pagado',
          value: 'Pagado',
        },
        {
          text: 'Parcial',
          value: 'Parcial',
        },
        {
          text: 'No pagado',
          value: 'No pagado',
        },
        {
          text: 'A devolver',
          value: 'A devolver',
        },
        {
          text: 'Bloqueada',
          value: 'Bloqueada',
        }
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (value) => {
        let color = '';
        if (value === 'Pagado') {
          color = 'green';
        } else if (value === 'Parcial') {
          color = 'orange';
        } else if (value === 'No pagado') {
          color = 'red';
        } else if (value === 'A devolver') {
          color = 'blue';
        }
        return (
          <Tag color={color} className={value === 'Bloqueada' ? 'bloqued' : ''}>
            {
              value === 'Bloqueada' ?
                <Lock style={{
                  fill: '#515151',
                  fontSize: 15,
                  marginTop: -3,
                  marginRight: 3
                }} /> : <></>
            }
            {value}
          </Tag>
        );
      }
    },
    {
      title: 'ID',
      dataIndex: 'identifier'
    },
    {
      title: 'PAX',
      dataIndex: 'pax',
      align: 'center',
      width: 50
    },
    {
      title: 'Importe',
      dataIndex: 'price',
      render: (text) => <span>{ Number(text).toFixed(2).replace(/[.,]00$/, "") + "€" }</span>
    },
    {
      title: 'Canal',
      dataIndex: 'channel',
      filters: channelList,
      onFilter: (value, record) => record.channel.indexOf(value) === 0,
    },
    {
      title: 'Fecha reserva',
      dataIndex: 'created_date',
      key: 'created_date',
      sorter: (a, b) => a.created_date.localeCompare(b.created_date),
    },
    {
      title: 'Cliente en actividad',
      dataIndex: 'client_arrived',
      align: 'center',
      className: 'arrived',
      render: (value) => value ?
        <CheckCircleTwoTone style={{ fontSize: '20px'}} twoToneColor="#52c41a" /> :
        <CloseCircleTwoTone style={{ fontSize: '20px'}} twoToneColor="#f56457" />
    },
  ]

  const columnsPlanningSet = (data, turnsList) => {
    let numAct = data.length
    let resourcesList = data.map((resource) => {
      return {
        title: resource.title.split('-').at(-1),
        children: [{
            title: 'Ocupados',
            dataIndex: resource.dataIndexBusy,
            width: `${100 / (numAct * 2)}%`,
            render: (text) => <span>{ text !== undefined ? text : "-" }</span>,
            align: 'center'
          },
          {
            title: 'Libres',
            dataIndex: resource.dataIndexAv,
            width: `${100 / (numAct * 2)}%`,
            render: (text) => <span>{ text !== undefined ? text : "-" }</span>,
            align: 'center'
          }
        ]
      }
    })
    resourcesList.unshift({
      title: 'Turno',
      dataIndex: "turn",
      width: 200,
      sorter: (a, b) => a.turn.localeCompare(b.turn),
      align: 'center',
      filters: turnsList,
      onFilter: (value, record) => record.turn.indexOf(value) === 0,
    })
    setPlanningColumns(resourcesList)
    setTableUpdate(tableUpdate + 1)
  }

  const getActivitiesTable = (date, centerId, activity) => {
    setIsLoading(true)
    //getAnotationsDay(date, centerId)
    apiManager.getActivitiesBookingGroup(centerId, date, activity)
    .then((res) => {
      //console.log(res.data.data)
      let result = res.data.data
      setDataTable(result)
      setIsLoading(false)
    })
  }

  const getSchedulerData = (date, centerId, activity) => {
    apiManager.getResourcesTableActivity(centerId, date, activity)
    .then(async(res) => {
      let result = res.data.data[0]
      //console.log(result)
      let eventsList = await createEventsList(result.occupations, result.resources)
      setSchedulerData({resources: result.resources, events: eventsList, date: new Date(date.split("-").reverse().join("/"))})
      setSchedulerFamily(null)
      setCounter(counter + 1)
    })
  }

  const createEventsList = (events, resources) => {
    return new Promise(resolve => {
      let eventsList = []
      events.map((event) => {
        eventsList.push({
          'event_id': event.event_id,
          'title': event.title,
          'start': new Date(event.start),
          'end': new Date(event.end),
          'assignee': event.assignee,
          'booking_id': event.booking_id,
          'sales_channel_id': event.sales_channel_id,
          'turn_id': event.turn_id,
          'cat_act_id': event.cat_act_id,
          'resource_id': resources.find(o => o.assignee === event.assignee).assignee,
          'resource_title': resources.find(o => o.assignee === event.assignee).text,
          'over_booking': event.over_booking,
          'client_arrived': event.client_arrived,
          'client_checking': event.client_checking,
          'booking_activity_id': event.booking_activity_id,
          'client_info': event.client_info,
          'sales_channel_info': event.sales_channel_info,
          'need_payment': event.need_payment,
          'exist_anottation': event.exist_anottation,
          'by_unit': event.by_unit,
          'num_pax': event.num_pax,
          'num_pax_total': event.num_pax_total,
          'is_vip': event.is_vip,
          'cat_act_label': event.cat_act_label
        })
      })
      resolve(eventsList)
    })
  }

  const prevDay = (currentDay) => {
    let fechaNueva = dayjs(currentDay).add(-1, 'day')
    setDateValue(fechaNueva.format('DD-MM-YYYY'))
    form.setFieldValue('datepicker', fechaNueva)
    if (familySelected !== null) {
      getActivitiesTable(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id, familySelected)
      getBookingTable(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id, familySelected)
      getSchedulerData(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id, familySelected)
    } else {
      setIsLoading(true)
      apiManager.getTurnsByCenter(JSON.parse(localStorage.getItem("activeCenter")).id, fechaNueva.format('DD-MM-YYYY'))
      .then((res) => {
        setFullTurns([])
        let turnsList = []
        res.data.data.map((turn) => {
          turnsList.push({
            'text': turn.title,
            'value': turn.title
          })
        })
        apiManager.planningFamilyTable(JSON.parse(localStorage.getItem("activeCenter")).id, fechaNueva.format('DD-MM-YYYY'))
        .then((res) => {
          let result = res.data.data
          setPlanningData(result.turnsInfo)
          columnsPlanningSet(result.activities, turnsList)
          setIsLoading(false)
        })
      })
    }
  }

  const nextDay = (currentDay) => {
    let fechaNueva = dayjs(currentDay).add(1, 'day')
    setDateValue(fechaNueva.format('DD-MM-YYYY'))
    form.setFieldValue('datepicker', fechaNueva)
    if (familySelected !== null) {
      getActivitiesTable(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id, familySelected)
      getBookingTable(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id, familySelected)
      getSchedulerData(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id, familySelected)
    } else {
      setIsLoading(true)
      apiManager.getTurnsByCenter(JSON.parse(localStorage.getItem("activeCenter")).id, fechaNueva.format('DD-MM-YYYY'))
      .then((res) => {
        setFullTurns([])
        let turnsList = []
        res.data.data.map((turn) => {
          turnsList.push({
            'text': turn.title,
            'value': turn.title
          })
        })
        apiManager.planningFamilyTable(JSON.parse(localStorage.getItem("activeCenter")).id, fechaNueva.format('DD-MM-YYYY'))
        .then((res) => {
          let result = res.data.data
          setPlanningData(result.turnsInfo)
          columnsPlanningSet(result.activities, turnsList)
          setIsLoading(false)
        })
      })
    }
  }

  const changeTableDay = (newDate, newDateFormated) => {
    setDateValue(newDateFormated)
    if (familySelected !== null) {
      getActivitiesTable(newDateFormated, JSON.parse(localStorage.getItem("activeCenter")).id, familySelected)
      getBookingTable(newDateFormated, JSON.parse(localStorage.getItem("activeCenter")).id, familySelected)
      getSchedulerData(newDateFormated, JSON.parse(localStorage.getItem("activeCenter")).id, familySelected)
    } else {
      setIsLoading(true)
      apiManager.getTurnsByCenter(JSON.parse(localStorage.getItem("activeCenter")).id, newDateFormated)
      .then((res) => {
        setFullTurns([])
        let turnsList = []
        res.data.data.map((turn) => {
          turnsList.push({
            'text': turn.title,
            'value': turn.title
          })
        })
        apiManager.planningFamilyTable(JSON.parse(localStorage.getItem("activeCenter")).id, newDateFormated)
        .then((res) => {
          let result = res.data.data
          setPlanningData(result.turnsInfo)
          columnsPlanningSet(result.activities, turnsList)
          setIsLoading(false)
        })
      })
    }
  }
  
  const summaryTable = (pageData) => {
    if (dataTable.length > 0 && isAdmin) {
      let avPlaces = 0;
      let avResources = 0;
      let bussyPlaces = 0;
      let bussyResources = 0;
      let totalBookings = 0;

      pageData.forEach(({ av_places, av_resources, bussy_places, bussy_resources, num_booking }) => {
        avPlaces += av_places === "" ? 0 : av_places;
        avResources += av_resources === "" ? 0 : av_resources;
        bussyPlaces += bussy_places === "" ? 0 : bussy_places;
        bussyResources += bussy_resources === "" ? 0 : bussy_resources;
        totalBookings += num_booking;
      });
      
      return (
        <>
          <Table.Summary.Row>
            <Table.Summary.Cell colSpan={2}><b>TOTAL</b></Table.Summary.Cell>
            {/* <Table.Summary.Cell><b>{bussyPlaces}</b></Table.Summary.Cell>
            <Table.Summary.Cell><b>{avPlaces}</b></Table.Summary.Cell> */}
            <Table.Summary.Cell><b>{bussyResources}</b></Table.Summary.Cell>
            <Table.Summary.Cell><b>{avResources}</b></Table.Summary.Cell>
            <Table.Summary.Cell><b>{totalBookings}</b></Table.Summary.Cell>
            <Table.Summary.Cell></Table.Summary.Cell>
          </Table.Summary.Row>
        </>
      )
    }
  }

  const summaryTableBooking = (pageData) => {
    if (dataTableBooking.length > 0 && isAdmin) {
      let totalPax = 0;
      let totalPrice = 0;

      pageData.forEach(({ pax, price }) => {
        totalPax += pax;
        totalPrice += price;
      });
      
      return (
        <>
          <Table.Summary.Row>
            <Table.Summary.Cell colSpan={5}><b>TOTAL</b></Table.Summary.Cell>
            <Table.Summary.Cell align="center"><b>{totalPax}</b></Table.Summary.Cell>
            <Table.Summary.Cell><b>{ Number(totalPrice).toFixed(2).replace(/[.,]00$/, "") + "€" }</b></Table.Summary.Cell>
            <Table.Summary.Cell colSpan={3}></Table.Summary.Cell>
          </Table.Summary.Row>
        </>
      )
    }
  }

  const onChange = (pagination, filters, sorter, extra) => {
    //console.log('params', pagination, filters, sorter, extra);
    if (pagination.pageSize !== paginationItems) {
      setPaginationItems(pagination.pageSize)
    }
  }

  const changeFamily = (value) => {
    let families = familiesData.find(({ id }) => id === value).children
    setFilterFamilies([])
    families.map((family) => {
      setFilterFamilies(current => [...current, {
        'text': family.name,
        'value': family.name,
        'id': family.id
      }])
    })
    setFamilySelected(value)
    let date = form.getFieldValue('datepicker')
    setDateValue(date.format('DD-MM-YYYY'))
    getActivitiesTable(date.format("DD-MM-YYYY"), JSON.parse(localStorage.getItem("activeCenter")).id, value)
    getBookingTable(date.format("DD-MM-YYYY"), JSON.parse(localStorage.getItem("activeCenter")).id, value)
    getSchedulerData(date.format("DD-MM-YYYY"), JSON.parse(localStorage.getItem("activeCenter")).id, value)
    apiManager.getTurnsForFamily(JSON.parse(localStorage.getItem("activeCenter")).id, value, date.format("DD-MM-YYYY"))
    .then((res) => {
      let turnsRes = res.data.data
      setOverbookingTurns([])
      turnsRes.map((turn) => {
        setOverbookingTurns(current => [...current, {
          'text': turn.title,
          'value': turn.title
        }])
      })
    })
    setChannelList([])
    apiManager.getChannels(JSON.parse(localStorage.getItem("activeCenter")).id)
    .then((res) => {
      res.data.data.map((channel) => {
        setChannelList(current => [...current, {
          'text': channel.title,
          'value': channel.title
        }])
      })
    })
  }

  const updatePageInfo = () => {
    let date = form.getFieldValue('datepicker')
    setDateValue(date.format('DD-MM-YYYY'))
    getActivitiesTable(date.format("DD-MM-YYYY"), JSON.parse(localStorage.getItem("activeCenter")).id, familySelected)
    getBookingTable(date.format("DD-MM-YYYY"), JSON.parse(localStorage.getItem("activeCenter")).id, familySelected)
    getSchedulerData(date.format("DD-MM-YYYY"), JSON.parse(localStorage.getItem("activeCenter")).id, familySelected)
  }

  const getBookingTable = (date, centerId, activity) => {
    apiManager.getBookingTableByActivity(centerId, date, activity)
    .then((res) => {
      //console.log(res.data.data)
      let result = res.data.data
      setDataTableBooking(result)
      setDataTableBase(result)
      /* if (text !== undefined) {
        searchOnTable(text)
      } */
    })
  }

  const openBookingInfo = (info, rowIndex, event) => {
    if (event.target.classList.contains('arrived') || event.target.nodeName === "path") {
      clientArrived(info.booking_activity_id, !info.client_arrived)
      /* confirmAlert({
        title: `${ !info.client_arrived ? "¿Ha llegado el cliente para la actividad" : "¿No ha llegado el cliente para la actividad" } ${info.identifier}?`,
        buttons: [
          {
            label: `${ !info.client_arrived ? "Aun no" : "Ha llegado" }`,
            //onClick: () => closeModal(),
            className: 'close-btn',
          },
          {
            label: `${ !info.client_arrived ? "Ha llegado" : "Aun no" }`,
            onClick: () => clientArrived(info.booking_activity_id, !info.client_arrived),
            className: 'confirm-btn',
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
        overlayClassName: "overlay-confirm-suprimir"
      }) */
    } else {
      /* console.log(info)
      console.log(rowIndex)
      console.log(event) */
      setBookingIdSelected(info)
      setIsModalBookingOpen(true)
    }
  }

  const clientArrived = (id, newState) => {
    //console.log(id)
    let data = {
      booking_activity_id: id,
      status: newState
    }
    apiManager.clientArrived(data)
    .then((res) => {
      //console.log(res.data.data)
      //let fechaNueva = form.getFieldValue('datepicker')
      //getBookingTable(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id, familySelected)
      updatePageInfo()
    })
  }

  const clientChecking = (id, newState) => {
    let data = {
      booking_activity_id: id,
      status: newState
    }
    apiManager.clientChecking(data)
    .then((res) => {
      updatePageInfo()
    })
  }

  const closeBookingInfoModal = () => {
    //let fechaNueva = form.getFieldValue('datepicker')
    formSearch.setFieldValue("text", "")
    //getBookingTable(fechaNueva.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id, familySelected)
    updatePageInfo()
    setIsModalBookingOpen(false)
  }

  const searchOnTable = (text) => {
    //console.log(text)
    if (text.text !== undefined && text.text !== "") {
      let tableData = dataTableBase
      //console.log(tableData)
      const filterResult = tableData.filter(
        (item) =>
          item.client_info
            .toLowerCase().includes(text.text.toLowerCase()) ||
          item.identifier
            .toLowerCase().includes(text.text.toLowerCase()) ||
          item.status.toLowerCase().includes(text.text.toLowerCase()) ||
          item.channel.toLowerCase().includes(text.text.toLowerCase()) ||
          item.activity.toLowerCase().includes(text.text.toLowerCase())
      );
      setDataTableBooking(filterResult)
    } else {
      //console.log(dataTableBase)
      setDataTableBooking(dataTableBase)
    }
  }

  const confirmCancelResume = (texto) => {
    confirmAlert({
      title: '¿Seguro que quieres ' + texto + '?',
      message: 'Se perderán todos los datos introducidos hasta el momento',
      buttons: [
        {
          label: 'Cerrar',
          onClick: () => setIsModalOpen(false),
          className: 'close-btn',
        },
        {
          label: 'Cancelar',
          className: 'cancel-btn',
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-confirm-suprimir"
    })
  }

  const closeFinishBooking = (date, channel, id) => {
    setIsModalOpen(false)
    let info = {
      id: id,
      channel_id: channel
    }
    //let fechaNueva = moment(date.split("-").reverse().join("-"));
    //setDateValue(fechaNueva.format('DD-MM-YYYY'))
    //form.setFieldValue('datepicker', dayjs(date.split("-").reverse().join("-")));
    updatePageInfo()
    /* let datee = form.getFieldValue('datepicker')
    getActivitiesTable(datee.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id, familySelected)
    getBookingTable(datee.format('DD-MM-YYYY'), JSON.parse(localStorage.getItem("activeCenter")).id, familySelected) */
    setBookingIdSelected(info)
    setIsModalBookingOpen(true)
    formSearch.setFieldValue("text", "")
  }

  const openBooking = (data) => {
    //console.log(data)
    setBookingIdSelected({
      id: data.booking_id,
      channel_id: data.sales_channel_id
    })
    setIsModalBookingOpen(true)
  }

  const openResources = (data) => {
    setResorcesInfo(data)
    setIsModalReasignOpen(true)
  }

  const openOverbookingResources = (data) => {
    setResorcesInfo(data)
    setIsModalOverbookingOpen(true)
  }

  const closeReasignModal = () => {
    updatePageInfo()
    setIsModalReasignOpen(false)
  }

  const closeOverbookingModal = () => {
    updatePageInfo()
    setIsModalOverbookingOpen(false)
  }

  const refreshFamilySelected = () => {
    setDataTable([])
    setPlanningData([])
    setPlanningColumns([])
    setFamilySelected(null)
    setIsLoading(true)
    let date = form.getFieldValue('datepicker')
    apiManager.getTurnsByCenter(JSON.parse(localStorage.getItem("activeCenter")).id, date.format("DD-MM-YYYY"))
    .then((res) => {
      setFullTurns([])
      let turnsList = []
      res.data.data.map((turn) => {
        turnsList.push({
          'text': turn.title,
          'value': turn.title
        })
      })
      apiManager.planningFamilyTable(JSON.parse(localStorage.getItem("activeCenter")).id, date.format("DD-MM-YYYY"))
      .then((res) => {
        let result = res.data.data
        setPlanningData(result.turnsInfo)
        columnsPlanningSet(result.activities, turnsList)
        setIsLoading(false)
      })
    })
  }

  const filterSchedulerTable = async(activity, text) => {
    let idAct = activity !== null ? activity.id : 0
    let actValue = activity !== null ? activity.value : null
    setIsLoading(true)
    setSchedulerFamily(actValue)
    setSchedulerText(text)
    let currentData = schedulerData
    let date = form.getFieldValue('datepicker')
    let centerId = JSON.parse(localStorage.getItem("activeCenter")).id
    let dataLoaded = await apiManager.getResourcesTableActivity(centerId, date.format("DD-MM-YYYY"), familySelected)
    let eventsList = await createEventsList(dataLoaded.data.data[0].occupations, dataLoaded.data.data[0].resources)
    let newArray = []
    if (idAct > 0) {
      for (const element of eventsList) {
        if (element.cat_act_id === idAct) {
          if (text !== null) {
            if (element.client_info.toLowerCase().includes(text.toLowerCase())) {
              newArray.push(element)
            }
          } else {
            newArray.push(element)
          }
        }
      }
      currentData.events = newArray
      setSchedulerData(currentData)
      setCounter(counter + 1)
      setIsLoading(false)
    } else if(text !== null) {
      for (const element of eventsList) {
        if (element.client_info.toLowerCase().includes(text.toLowerCase())) {
          newArray.push(element)
        }
      }
      currentData.events = newArray
      setSchedulerData(currentData)
      setCounter(counter + 1)
      setIsLoading(false)
    } else {
      currentData.events = eventsList
      setSchedulerData(currentData)
      setCounter(counter + 1)
      setIsLoading(false)
    }
  }

  return (
    <>
      <Loader controller={isLoading} />
      <Modal
        maskClosable={false}
        onCancel={() => closeReasignModal()}
        centered={true}
        open={isModalReasignOpen}
        destroyOnClose={true}
        cancelText="Cerrar"
        footer={null}
      >
        {
          resorcesInfo !== null ?
            resorcesInfo.by_unit ?
              <ReasignResources data={resorcesInfo} closeReasign={closeReasignModal} />
            :
              <ReasignResourcesPax data={resorcesInfo} closeReasign={closeReasignModal} />
          : <></>
        }
      </Modal>
      <Modal
        maskClosable={false}
        onCancel={() => closeOverbookingModal()}
        centered={true}
        open={isModalOverbookingOpen}
        destroyOnClose={true}
        cancelText="Cerrar"
        footer={null}
      >
        <OverbookingResources data={resorcesInfo} closeReasign={closeOverbookingModal} />
      </Modal>
      <Modal
        maskClosable={false}
        onCancel={() => closeBookingInfoModal()}
        centered={true}
        open={isModalBookingOpen}
        width={1000}
        destroyOnClose={true}
        cancelText="Cerrar"
        footer={null}
      >
        <BookingInfo
          bookingInfoData={bookingIdSelected}
          bookingClose={closeBookingInfoModal}
          selectedDate={dateValue}
        />
      </Modal>
      <Modal
        maskClosable={false}
        onCancel={() => confirmCancelResume('cerrar la ventana')}
        centered={true}
        open={isModalOpen}
        width={1140}
        destroyOnClose={true}
        cancelText="Cancelar reserva"
        footer={null}
      >
        <BookingCreate
          closeFinishBooking={closeFinishBooking}
        />
        <Divider />
        <Row justify="end">
          <Col>
            <Button
              onClick={() => confirmCancelResume('cancelar la reserva')}
              className="basic-btn"
            >Cancelar reserva</Button>
          </Col>
        </Row>
      </Modal>
      <Row
        justify={'space-between'}
        style={{marginBottom: 20}}
        //className={styles.header}
      >
        <Col span={8} style={{display: "flex", justifyContent: "left", alignItems: "center"}}>
          <Select
            style={{ width: 250 }}
            placeholder="Selecciona una familia de actividad"
            onChange={(e) => changeFamily(e)}
            value={familySelected}
            options={familiesList}
          />
          <Button
            disabled={familySelected !== null ? false : true}
            onClick={() => refreshFamilySelected()}
          ><Refresh /></Button>
        </Col>
        <Col span={8} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Form
            form={form}
            ref={formRef}
            initialValues={{datepicker: dayjs()}}
            style={{display: "flex", gap: 10}}
          >
            <Button onClick={() => prevDay(form.getFieldValue('datepicker'))}><LeftOutlined /></Button>
            <Form.Item
              name="datepicker"
              style={{marginBottom: 0}}
            >
              <DatePicker
                allowClear={false}
                //defaultValue={dayjs()}
                format={dateFormat}
                ref={dateSelected}
                className="datapickerElement"
                //value={setDateValue}
                //bordered={false}
                onChange={(newDate, newDateFormated) => changeTableDay(newDate, newDateFormated)}
              />
            </Form.Item>
            <Button onClick={() => nextDay(form.getFieldValue('datepicker'))} style={{marginLeft: "-5px"}}><RightOutlined /></Button>
          </Form>
        </Col>
        <Col span={8} style={{display: "flex", justifyContent: "right", alignItems: "center"}}>
          {
            familySelected !== null ?
              <Tooltip placement="bottom" title="Refrescar datos">
                <Button
                  type="primary"
                  shape="round"
                  size='large'
                  className="borded-btn circle"
                  onClick={() => updatePageInfo()}
                  style={{marginRight: 6}}
                >
                  <Refresh style={{fontSize: 25, fill: "#FFF"}} />
                </Button>
              </Tooltip>
            : ""
          }
          <Button
            type="primary"
            shape="round"
            size='large'
            className="borded-btn"
            onClick={() => setIsModalOpen(true)}
            style={{marginRight: 6}}
          >
            <p><span>+</span>Añadir reserva</p>
          </Button>
        </Col>
      </Row>
      {
        //dataTable.length > 0 ?
        familySelected !== null ?
          <>
          {/* <Table
            columns={columns}
            dataSource={dataTable} 
            bordered
            onChange={onChange}
            showHeader={familySelected !== null ? true : false}
            className={familySelected !== null ? "mainTable" : "emptyTable"}
            pagination={false}
            scroll={familySelected !== null ? {y: 300} : false}
            locale={{ emptyText: (
              <>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    familySelected !== null ?
                      <span>No se han encontrado datos</span>
                    :
                      <span>Selecciona una familia de actividad<br />para obtener datos</span>
                  }
                />
              </>
              )
            }}
            summary={pageData => summaryTable(pageData)}
          /> */}
          </>
        :
          <Table
            className="planningTable"
            bordered
            key={tableUpdate}
            columns={planningColumns}
            dataSource={planningData}
            pagination={false}
          />
      }
      {
        familySelected !== null ?
          <Tabs
            defaultActiveKey="2"
            centered
            style={{marginTop: 20}}
            items={[
              {
                label: "Recursos",
                key: "2",
                children:
                <>
                  {/* <SchedulerResources
                    key={counter}
                    data={schedulerData}
                    openBookingModal={openBooking}
                    openReasignResources={openResources}
                    openOverbookingResources={openOverbookingResources}
                    clientArrivedFunc={clientArrived}
                    clientCheckingFunc={clientChecking}
                    centerAdmin={isCenterAdmin}
                  /> */}
                  <SchedulerTable
                    key={counter}
                    data={schedulerData}
                    openBookingModal={openBooking}
                    openReasignResources={openResources}
                    openOverbookingResources={openOverbookingResources}
                    clientArrivedFunc={clientArrived}
                    clientCheckingFunc={clientChecking}
                    centerAdmin={isCenterAdmin}
                    families={filterFamilies}
                    filterTable={filterSchedulerTable}
                    familySelected={schedulerFamily}
                    textFilter={schedulerText}
                  />
                  {/* <Scheduler
                    view="day"
                    resources={schedulerResources}
                    events={schedulerEvents}
                    hourFormat="24"
                    selectedDate={schedulerDate}
                    navigation={false}
                    editable={false}
                    draggable={false}
                    deletable={false}
                    day={{
                      startHour: 9,
                      endHour: 18,
                      step: 30
                    }}
                    recourseHeaderComponent={(resoruce) => { return(
                      <p style={{textAlign: "center"}}>{resoruce.text}</p>
                    )}}
                  /> */}
                </>
              },
              {
                label: "Reservas",
                key: "1",
                children:
                  <>
                    <Row style={{marginBottom: 20}}>
                      <Col span={8}>
                      </Col>
                      <Col span={8}>
                      </Col>
                      <Col span={8} style={{display: "flex", justifyContent: "right", alignItems: "center"}}>
                        <Form
                          className="formSerachBooking"
                          form={formSearch}
                          onFinish={(values) => searchOnTable(values)}
                          style={{marginRight: 20}}
                        >
                          <Form.Item
                            name="text"
                            style={{marginBottom: 0}}
                          >
                            <Input
                              placeholder="Buscar..."
                            />
                          </Form.Item>
                          <Form.Item
                            style={{marginBottom: 0}}
                          >
                            <Button
                              className="iconCircle"
                              htmlType="submit"
                            ><SearchOutlined className="white" /></Button>
                          </Form.Item>
                        </Form>
                      </Col>
                    </Row>
                    <Table
                      columns={columnsBooking}
                      showHeader={dataTableBooking.length > 0 ? true : false}
                      dataSource={dataTableBooking}
                      bordered
                      className={`mainTable${dataTableBooking.length > 0 ? "" : " emptyMainTable"}`}
                      pagination={
                        {
                          total: dataTableBooking.length,
                          pageSize: paginationItems,
                          //defaultPageSize: 50,
                          showSizeChanger: true,
                          pageSizeOptions: ['50', '100', '500', dataTableBooking.length],
                          locale: { items_per_page: ""},
                          showTotal: (total) => `${total} ${total < 2 ? "actividad" : "actividades"} hoy`,
                        }
                      }
                      summary={pageData => summaryTableBooking(pageData)}
                      onRow={(record, rowIndex) => { return {onClick: event => openBookingInfo(record, rowIndex, event)}}}
                      locale={{ emptyText: (
                        <>
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={<span>No hay reservas en este día para<br />la família de actividad seleccionada</span>}
                          />
                        </>
                        )
                      }}
                    />
                  </>
              }
            ]}
          />
        : ""
      }
    </>
  )
}