import React, { useState, useEffect } from "react"
import { Table, Tooltip, Tag, Button } from "antd"
import apiManager from "../../api/apiManager"
import Loader from "../Loader/Loader"
import { SyncProblem, ContactEmergency, Replay, PersonOutline, Download } from "@mui/icons-material"
import DeliveryModal from "../Modals/DeliveryModal"

export default function TableDelivery() {

  useEffect(()=> {
    loadTableData()
  }, [])

  const [isLoading, setIsLoading] = useState(false)
  const [dataTable, setDataTable] = useState([])
  const [periodDelivery, setPeriodDelivery] = useState([])
  const [channelsDelivery, setChannelsDelivery] = useState([])
  const [socialDelivery, setSocialDelivery] = useState([])
  const [bookingsDelivery, setBookingsDelivery] = useState([])
  const [isModalDelivery, setIsModalDelivery] = useState(false)
  const [deliveryData, setDeliveryData] = useState([])

  const loadTableData = () => {
    setIsLoading(true)
    apiManager.listDelivery(JSON.parse(localStorage.getItem("activeCenter")).id)
    .then((res) => {
      let result = res.data.data
      filterSocialDelivery(result)
      filterChannelsDelivery(result)
      filterPeriodsDelivery(result)
      filterBookingsDelivery(result)
      setDataTable(result)
      //console.log(result)
      setIsLoading(false)
    })
  }

  const filterPeriodsDelivery = (elements) => {
    setPeriodDelivery([])
    let list = []
    elements.map(delivery => {
      if (list.some((e) => e.value === delivery.delivery_period)) {
        
      } else {
        list.push({value: delivery.delivery_period})
        setPeriodDelivery(current => [...current, {
          'text': delivery.delivery_period,
          'value': delivery.delivery_period
        }])
      }
    })
  }

  const filterChannelsDelivery = (elements) => {
    setChannelsDelivery([])
    let list = []
    elements.map(delivery => {
      if (list.some((e) => e.value === delivery.sales_channel)) {
        
      } else {
        list.push({value: delivery.sales_channel})
        setChannelsDelivery(current => [...current, {
          'text': delivery.sales_channel,
          'value': delivery.sales_channel
        }])
      }
    })
  }

  const filterSocialDelivery = (elements) => {
    setSocialDelivery([])
    let list = []
    elements.map(delivery => {
      if (list.some((e) => e.value === delivery.social_fact) || delivery.social_fact === "") {
        
      } else {
        list.push({value: delivery.social_fact})
        setSocialDelivery(current => [...current, {
          'text': delivery.social_fact,
          'value': delivery.social_fact
        }])
      }
    })
  }

  const filterBookingsDelivery = (elements) => {
    setBookingsDelivery([])
    let list = []
    elements.map(delivery => {
      delivery.bookings.map(booking => {
        if (list.some((e) => e.value === booking)) {
        
        } else {
          list.push({value: booking})
          setBookingsDelivery(current => [...current, {
            'text': booking,
            'value': booking
          }])
        }
      })
    })
  }

  const recreateDelivery = (value) => {
    setIsLoading(true)
    let data = {delivery_id: value}
    apiManager.recreateDelivery(data)
    .then((res) => {
      setIsLoading(false)
      loadTableData()
    })
  }

  const openInfoDelivery = (value) => {
    apiManager.getDelivery(value)
    .then((res) => {
      let result = res.data.data[0]
      setDeliveryData(result)
      setIsModalDelivery(true)
      //console.log(result)
    })
  }

  const closeInfoDelivery = () => {
    setIsModalDelivery(false)
  }

  const columns = [
    {
      title: '',
      dataIndex: 'delivery_id',
      key: 'status_delivery',
      align: 'center',
      render: (item, record) =>
        <div style={{display: 'flex', gap: 5, justifyContent: 'center'}}>
          {
            record.need_recreate ?
              <Tooltip title="Necesita realbaraneado"><Replay style={{fill: '#fff', backgroundColor: 'orange', borderRadius: '50%'}} /></Tooltip>
            : <></>
          }
          {
            record.need_info_client ?
            <Tooltip title="Requiere información del cliente"><PersonOutline style={{fill: '#fff', backgroundColor: 'red', borderRadius: '50%'}} /></Tooltip>
            : <></>
          }
        </div>
    },
    {
      title: 'Fecha albarán',
      dataIndex: 'delivery_date',
      key: 'delivery_date',
      sorter: (a, b) => a.delivery_date.localeCompare(b.delivery_date),
    },
    {
      title: 'ID Albarán',
      dataIndex: 'delivery_id',
      key: 'delivery_id'
    },
    {
      title: 'Periodo albarán',
      dataIndex: 'delivery_period',
      key: 'delivery_period',
      filters: periodDelivery,
      onFilter: (value, record) => record.delivery_period.includes(value) === true,
    },
    {
      title: 'Canal',
      dataIndex: 'sales_channel',
      key: 'sales_channel',
      filters: channelsDelivery,
      onFilter: (value, record) => record.sales_channel.indexOf(value) === 0,
    },
    {
      title: 'Razón social',
      dataIndex: 'social_fact',
      key: 'social_fact',
      filters: socialDelivery,
      onFilter: (value, record) => record.social_fact.includes(value) === true,
    },
    {
      title: 'Listado reservas',
      dataIndex: 'bookings',
      key: 'bookings',
      width: 200,
      filters: bookingsDelivery,
      filterSearch: true,
      onFilter: (value, record) => record.bookings.includes(value) === true,
      render: (element) => element.length > 4 ?
        element.map((id, i, row) => {
          if (i < 3) {
            return <Tag className="simple">{element[i]}</Tag>
          } else if(i === 4) {
            return <Tooltip color="#fff" placement="right" title={
              element.map((ide, index, rowe) => {
                if (index > 2) {
                  return <Tag className="simple">{element[index]}</Tag>
                }
              })
            }>
              <Tag className="simple">{ "+" + (element.length - 3).toString() }</Tag>
            </Tooltip>
          }
        })
      : element.map((id, i, row) =>
        <Tag className="simple">{element[i]}</Tag>
        )
    },
    {
      title: 'Cliente',
      dataIndex: 'client',
      key: 'client',
    },
    {
      title: 'Importe total',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: (text) => <span>{ Number(text).toFixed(2).replace(/[.,]00$/, "") + "€" }</span>
    },
    {
      title: 'Forzar "realbaranear"',
      dataIndex: 'force_redelivery',
      key: 'force_redelivery',
      align: 'center',
      render: (text, record) =>
        <Button
          style={{display: 'flex', gap: 5, margin: 'auto'}}
          className="main-btn"
          icon={<Replay style={{fill: '#FFF'}} />}
          onClick={() => recreateDelivery(record.delivery_id)}
        >Recreate</Button>
    },
    {
      title: 'Descargar albarán',
      dataIndex: 'delivery_download',
      key: 'delivery_download',
      align: 'center',
      render: (text, record) =>
        <Button
          style={{display: 'flex', gap: 5, margin: 'auto'}}
          className="main-btn"
          icon={<Download style={{fill: '#FFF'}} />}
          onClick={() => openInfoDelivery(record.delivery_id)}
        >Descargar</Button>
    },
  ]

  return (
    <>
      <Loader controller={isLoading} />
      <DeliveryModal
        open={isModalDelivery}
        delivery={deliveryData}
        closeInfoDeliveryFunc={closeInfoDelivery}
      />
      <Table
        columns={columns}
        dataSource={dataTable} 
        bordered
        //onChange={onChange}
        className="mainTable"
        //onRow={(record, rowIndex) => { return {onClick: event => console.log(record, rowIndex, event)}}}
        rowKey={record => record.delivery_id}
      />
    </>
  )
}