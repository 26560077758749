import React from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import PrivateRoute from "../../componentes/PrivateRoute/PrivateRoute";
import NewAuth from "../../componentes/Auth/NewAuth";
import Page404 from "../../componentes/Error/Page404";

/* LAYOUTS */
import B2BMainLayout from "../../layouts/B2BMainLayout";
import MainLayout from "../../layouts/MainLayout";

/* MAIN MENU */
import Reservas from "../Reservas/reservas";
import Actividades from "../Actividades/actividades";
import BuscadorReservas from "../BuscadorReservas/BuscadorReservas";
import MensualProduction from "../Informes/MensualProduction";
import ChannelProduction from "../Informes/ChannelProduction";
import ClosedCash from "../Informes/ClosedCash";
import ActivitiesReports from "../Informes/ActivitiesReports";
import ValidateBookings from "../ValidateBookings/ValidateBookings";
import InvoiceIncomplete from "../InvoiceIncomplete/InvoiceIncomplete";
import DeliveryList from "../Facturas/DeliveryList";
import InvoiceList from "../Facturas/InvoiceList";
import InvoiceBook from "../Facturas/InvoiceBook";
import TreasuryBook from "../Facturas/TreasuryBook";
import Reportings from "../Facturas/Reportings";
import RectInvoice from "../Facturas/RectInvoice";

/* B2B MENU */
import ReservasB2B from "../B2B/ReservasB2B";
import BuscadorB2B from "../B2B/BuscadorB2B";

export default function WebRoutes() {

  const navigate = useNavigate()

  const refreshScreen = (isB2B) => {
    if (!isB2B) {
      navigate("/reservas")
    } else {
      navigate("/b2b/reservas")
    }
  }

  return (
    <React.Fragment>
      <Routes>
      <Route exact path="/" element={<NewAuth refreshScreen={refreshScreen} />} />
        <Route exact path="/reservas"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Reservas"
              >
                <Reservas />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/actividades"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Actividades"
              >
                <Actividades />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/reservas-buscador"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Buscador de reservas"
              >
                <BuscadorReservas />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/produc-mensual"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Producción mensual"
              >
                <MensualProduction />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/produc-canal"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Producción por canal de venta"
              >
                <ChannelProduction />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/cierres-caja"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Cierre de caja"
              >
                <ClosedCash />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/informe-actividades"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Informes de actividades"
              >
                <ActivitiesReports />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/validar-reservas"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Reservas a validar"
              >
                <ValidateBookings />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/facturacion-incompleta"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Facturación incompleta"
              >
                <InvoiceIncomplete />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/listado-albaranes"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Listado albaranes"
              >
                <DeliveryList />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/listado-facturas"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Listado facturas"
              >
                <InvoiceList />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/libro-facturas"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Libro de facturas"
              >
                <InvoiceBook />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/libro-tesoreria"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Libro de tesorería"
              >
                <TreasuryBook />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/reportings"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Reportings"
              >
                <Reportings />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/facturas-rect"
          element={
            <PrivateRoute b2b={false}>
              <MainLayout
                title="Facturas rectificativas"
              >
                <RectInvoice />
              </MainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/b2b/reservas"
          element={
            <PrivateRoute b2b={true}>
              <B2BMainLayout
                title="Reservas"
              >
                <ReservasB2B />
              </B2BMainLayout>
            </PrivateRoute>
          }
        />
        <Route exact path="/b2b/reservas-buscador"
          element={
            <PrivateRoute b2b={true}>
              <B2BMainLayout
                title="Buscador de reservas"
              >
                <BuscadorB2B />
              </B2BMainLayout>
            </PrivateRoute>
          }
        />
        <Route path='/informes' element={<></>}/>
        <Route path='/facturas' element={<></>}/>
        <Route path='/b2b' element={<></>}/>
        <Route path='*' element={<Page404 />}/>
      </Routes>
    </React.Fragment>
  )
}