import React, { useEffect, useState, useRef } from "react"
import { Modal, Row, Col, Table, Divider, Tooltip, Button } from "antd"
import { Print } from "@mui/icons-material"
import ReactToPrint from "react-to-print"
import "./simpleTable.css"

export default function DeliveryModal(props) {

  useEffect(() => {
    //console.log(props.delivery)
    /* if (props.delivery.bookings !== undefined) {
      setDataTable([])
      for(let booking of props.delivery.bookings) {
        setDataTable(current => [...current, {
          'start_date': booking.start_date,
          'identifier': booking.identifier,
          'internal_ref': booking.internal_ref,

          'value': delivery.sales_channel
        }])
      }
      setDataTable([])
    } */
  }, [props])

  const [dataTable, setDataTable] = useState([])
  const [totalSum, setTotalSum] = useState("0")

  let componentRef = useRef()

  const loadDataTable = (data) => {
    let dataTable = []
    let values = data.booking_activity
    for(let act of values) {
      dataTable.push({
        name_act: act.name,
        num_pax: act.num_pax,
        total_iva: act.total_iva,
        total_price: act.total_price,
      })
    }
    return dataTable
  }

  const loadDataTableTop = (data) => {
    let dataTable = [{
      start_date: data.start_date,
      identifier: data.identifier,
      name_act: data.client_info,
      internal_ref: data.internal_ref,
    }]
    return dataTable
  }

  const loadDataTableIVA = (data) => {
    let dataTable = [
      {
        title: "Base imponible",
        first: data.base_imponible_iva,
        second: data.base_imponible_total,
      },
      {
        title: "Importe IVA",
        first: data.import_iva,
        second: data.total_iva,
      }
    ]
    //let sum = dataTable.reduce((accumulator, object) => { return accumulator + Number(object.second) }, 0)
    return dataTable
  }

  const columns = [
    {
      title: 'Fecha inicio',
      dataIndex: 'start_date',
      key: 'start_date',
      width: 100,
    },
    {
      title: 'Num. Reserva',
      dataIndex: 'identifier',
      key: 'identifier',
      width: 100,
    },
    {
      title: 'Nombre actividad',
      dataIndex: 'name_act',
      key: 'name_act',
    },
    {
      title: 'Ref. Interna',
      dataIndex: 'internal_ref',
      key: 'internal_ref',
      width: 150,
    },
    {
      title: 'Personas',
      dataIndex: 'num_pax',
      key: 'num_pax',
      align: 'center',
      width: 80,
    },
    {
      title: 'Tipo IVA',
      dataIndex: 'total_iva',
      key: 'total_iva',
      align: 'right',
      width: 100,
      render: (text) => text === undefined ? "" : text + "%"
    },
    {
      title: 'Total IVA incluido',
      dataIndex: 'total_price',
      key: 'total_price',
      align: 'right',
      width: 100,
      render: (text) => text === undefined ? "" : text + "€"
    }
  ]

  const columnsIVA = [
    {
      title: '',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '',
      dataIndex: 'first',
      key: 'first',
      align: 'right',
      width: 100,
      render: (text) => text === undefined ? "" : text + "%"
    },
    {
      title: '',
      dataIndex: 'second',
      key: 'second',
      align: 'right',
      width: 100,
      render: (text) => text === undefined ? "" : text + "€"
    }
  ]

  return (
    
    <Modal
      maskClosable={false}
      onCancel={() => props.closeInfoDeliveryFunc()}
      centered={true}
      open={props.open}
      width={1000}
      destroyOnClose={true}
      cancelText="Cerrar"
      footer={null}
    >
      {
        props.delivery.delivery_id !== undefined ?
          <>
            <ReactToPrint
              trigger={() => <Tooltip title="Imprimir documento">
                <Button style={{position: 'absolute', top: 8, left: 8, height: 'auto'}}><Print style={{fontSize: 30}} /></Button>
              </Tooltip>}
              content={() => componentRef}
              documentTitle={`delivery-${props.delivery.fiscal_company.social_reason !== undefined ? props.delivery.fiscal_company.social_reason : 0}`}
            />
            {/* <DocToPrint ref={(el) => (componentRef = el)} /> */}
            <div ref={(el) => (componentRef = el)}>
              <Row style={{padding: 30, columns: 1}}>
                <Col span={11}>
                  <p>{props.delivery.fiscal_company.social_reason}</p>
                  <p>{props.delivery.fiscal_company.fiscal_address}</p>
                  <p>Teléfono {props.delivery.fiscal_company.phone} - { props.delivery.fiscal_company.fiscal_email }</p>
                </Col>
                <Col span={11} offset={2}></Col>
                <Col span={24}>
                  <Table
                    style={{breakInside: 'avoid', marginTop: 30}}
                    columns={columns}
                    bordered
                    pagination={false}
                    className="mainTable simpleTable noBody colored"
                    rowKey={record => record.identifier}
                  />
                  {
                    props.delivery.bookings.map(booking => {
                      return (
                        <>
                          {
                            booking.booking_activity.length > 0 ?
                              <div style={{breakInside: 'avoid'}}>
                                <Table
                                  showHeader={false}
                                  columns={columns}
                                  dataSource={loadDataTableTop(booking)}
                                  bordered
                                  pagination={false}
                                  className="mainTable simpleTable coloredTwo"
                                  rowKey={record => record.identifier}
                                />
                                <Table
                                  showHeader={false}
                                  columns={columns}
                                  dataSource={loadDataTable(booking)}
                                  bordered
                                  pagination={false}
                                  className="mainTable simpleTable"
                                  rowKey={record => record.identifier}
                                />
                              </div>
                            : <></>
                          }
                        </>
                      )
                    })
                  }
                </Col>
              </Row>
              <Row style={{padding: 30, columns: 1, breakInside: 'avoid', width: '100%'}}>
                <Col span={8} offset={16}>
                  {
                    props.delivery.iva_information.map(iva => {
                      return (
                        <Table
                          style={{marginTop: 20}}
                          showHeader={false}
                          columns={columnsIVA}
                          dataSource={loadDataTableIVA(iva)}
                          bordered
                          pagination={false}
                          className="mainTable simpleTable colored"
                          rowKey={record => record.total_iva}
                        />
                      )
                    })
                  }
                  <h3 style={{display: 'flex', justifyContent: 'space-between', marginTop: 20}}>
                    IMPORTE TOTAL: <b style={{fontSize: '2rem'}}>{ Number(props.delivery.total_price).toFixed(2).replace(/[.,]00$/, "") + "€" }</b>
                  </h3>
                </Col>
              </Row>
            </div>
          </>
        : <></>
      }
    </Modal>
  )
}